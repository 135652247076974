<template>
  <div class="marketing-league-issuance-record">
    <PageTitle :title="$route.meta.title" hideBtn />
    <FiltersContainer>
      <el-input
        v-model="search.name"
        clearable
        placeholder="輸入活動名稱"
        @change="handleSearch"
      />
    </FiltersContainer>
    <section>
      <IssuanceTable :data="eventHistory" @view="viewHandler" />
      <Pagination
        :curPage.sync="search.page"
        :pageLimit="search.limit"
        :total="search.dataCount"
        @pageChange="handlePageChange"
      />
    </section>
    <div>
      <el-dialog :title="dialog.title" :visible.sync="dialog.visible" :close-on-click-modal="false">
        <el-form label-width="120px" label-position="top">
          <el-form-item
            v-for="field in tableColumns"
            :key="field.name"
            :label="field.label"
            :prop="field.prop || field.name"
          >
            <div :class="{ 'img-wrapper': field.isImg }">
              <img v-if="field.isImg" :src="getEventData(field.key || field.name)" alt="">

              <span v-else>{{ getEventData(field.key || field.name) }}</span>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IssuanceTable from './components/IssuanceTable'
import { get as _get } from 'lodash'

const elLoadingOptions = {
  lock: true,
  target: '.marketing-league-issuance-record',
}

export default {
  name: 'MarketingLeagueIssuanceRecord',
  components: { IssuanceTable },
  data () {
    return {
      search: {
        name: '',
        page: 1,
        limit: 10,
        dataCount: 1,
      },
      dialog: {
        title: '檢視活動',
        visible: false,
      },
      tableColumns: [
        {
          name: 'img',
          label: '圖片',
          key: 'img.imageURL',
          isImg: true,
        },
        {
          name: 'name',
          label: '活動名稱',
        },
        {
          name: 'eventDuration',
          label: '參加期限',
          key: 'eventDuration_',
        },
        {
          name: 'issuanceAmount',
          label: '實際發放數量',
        },
        {
          name: 'usageAmount',
          label: '實際使用數量',
        },
        {
          name: 'description',
          label: '活動內容',
        },
        {
          name: 'redeemDuration',
          label: '使用期限',
          key: 'redeemDuration_',
        },
        {
          name: 'discountType',
          label: '優惠形式',
          key: 'discountType_',
        },
        {
          name: 'discountAmout',
          label: '金額/比例',
        },
        {
          name: 'shopCategory',
          label: '參加類別',
          key: 'shopCategoryName',
        },
        {
          name: 'shopUrl',
          label: '網址',
        },
      ],
      viewData: {},
    }
  },
  computed: {
    ...mapGetters('marketing-league', {
      eventHistory: 'eventHistory',
    }),
    getEventData () {
      return (key) => _get(this.viewData, key, '')
    },
  },
  mounted () {
    this.getEventHistory()
  },
  methods: {
    async getEventHistory () {
      const loading = this.$loading(elLoadingOptions)
      const { count } = await this.$store.dispatch('marketing-league/GET_PAGE_PREFERENTIAL_PLAN', this.search)
      this.search.dataCount = count
      loading.close()
    },
    viewHandler (viewData) {
      this.viewData = { ...viewData }
      this.dialog.visible = true
    },
    handlePageChange (page) {
      this.search.page = page
      this.getEventHistory()
    },
    handleSearch () {
      this.search.page = 1
      this.getEventHistory()
    },
  },
}
</script>

<style lang="scss">
.marketing-league-issuance-record {
  .img-wrapper {
    width: 90px;
    height: 90px;
    background-image: url('../../assets/icon/img-placeholder.png');
    border-radius: 4px;
    overflow: hidden;
  }
}
</style>
