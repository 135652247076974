<template>
  <div class="issuance-table">
    <el-table :data="eventData" div class="setting-table">
      <el-empty
        v-show="data.length === 0"
        slot="empty"
        :description="text.noData"
        :image="require('@/assets/icon/nodata/no_data.svg')"
      />
      <el-table-column
        v-for="col in tableColumns"
        :key="col.name"
        :prop="col.name"
        :label="col.label"
        :width="col.width"
        :fixed="col.fixed"
        :sortable="col.sortable"
        :show-overflow-tooltip="col.overflow"
        align="center"
      >
        <template slot-scope="scope">
          <TableEditBtnGroup
            v-if="scope.column.property === 'opt'"
            editBtn="檢視"
            hideDelete
            @edit="emitEvent('view', scope.row)"
          />
          <!-- <div v-if="scope.column.property === 'opt'">
            <el-button type="text" class="table-opt-edit" @click="emitEvent('view', scope.row)">檢視</el-button>
          </div> -->
          <span v-else v-html="scope.row[scope.column.property]" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { text } from '@/constants'

export default {
  name: 'IssuanceTable',
  props: {
    data: { type: Array, required: true },
  },
  data () {
    return {
      text,
      tableColumns: [
        {
          name: 'name',
          label: '活動名稱',
          width: 120,
        },
        {
          name: 'eventDuration',
          label: '參加期限',
          width: 120,
        },
        {
          name: 'issuanceAmount',
          label: '實際發放數量',
          width: 130,
        },
        {
          name: 'usageAmount',
          label: '實際使用數量',
          width: 130,
        },
        {
          name: 'description',
          label: '活動內容',
          width: 'auto',
          overflow: true,
        },
        {
          name: 'discountAmout',
          label: '金額/比例',
          width: 120,
        },
        {
          name: 'redeemDuration',
          label: '使用期限',
          width: 120,
        },
        {
          name: 'opt',
          label: '操作',
          width: 100,
          fixed: 'right',
        },
      ],
    }
  },
  computed: {
    eventData () {
      return this.data.map(item => {
        const {
          startDate,
          endDate,
          redeemEndDate,
          redeemStartDate,
        } = item
        return {
          ...item,
          eventDuration: `${startDate} <br> 至 <br> ${endDate}`,
          redeemDuration: `${redeemStartDate} <br> 至 <br> ${redeemEndDate}`,
        }
      })
    },
  },

  methods: {
    emitEvent (event, data) {
      this.$emit(event, data)
    },
  },
}
</script>

<style lang="scss">
.issuance-table{
}
</style>
